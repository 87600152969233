@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

header {
  margin-bottom: 113px;
}

#header,
#header ul {
  background-color: #fff;
}
.icon,
.text {
  vertical-align: middle;
  display: inline-block;
}

/* #video-player.playing {
  width: 100%;
  height: 100%;
} */

.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.grabbable.active {
  opacity: 0.7;
}

.close-rectangle__button {
  width: 19px;
  height: 19px;
  background-color: rgba(155, 155, 155, 0.3);
  transition: background-color 0.2s ease-in;
}

.close-rectangle__button:hover {
  background-color: rgba(155, 155, 155, 0.8);
}

.close-rectangle__button:before,
.close-rectangle__button:after {
  content: '';
  position: absolute;
  background-color: #333;
  height: 15px;
  width: 2px;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 3px;
}

.close-rectangle__button:before {
  transform: rotate(45deg);
}

.rc-tooltip {
  background-color: unset !important;
}
.rc-tooltip .rc-tooltip-inner {
  padding: 14px;
  border-radius: 6px;
  border-color: #eeeeee;
}

.close-rectangle__button:after {
  transform: rotate(-45deg);
}

.contamination__container {
  padding: 5px;
  background-color: rgba(155, 155, 155, 0.5);
  font-weight: 500;
}

.contamination__item {
  padding: 3px;
  transition: background-color 0.2s ease-in;
}

.contamination__item:hover {
  background-color: rgba(155, 155, 155, 0.8);
  cursor: pointer;
}

/* 404 page */
#notfound {
  position: relative;
  height: 100vh;
  background: #f6f6f6;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 767px;
  width: 100%;
  line-height: 1.4;
  padding: 110px 40px;
  text-align: center;
  background: #fff;
  -webkit-box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 15px -10px rgba(0, 0, 0, 0.1);
}

.notfound .notfound-404 {
  position: relative;
  height: 180px;
}

.notfound .notfound-404 h1 {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 165px;
  font-weight: 700;
  margin: 0px;
  color: #3374b0;
  text-transform: uppercase;
}

.notfound .notfound-404 h1 > span {
  color: #00af4d;
}

.notfound .underline {
  color: #3374b0;
}

.notfound h2 {
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  font-weight: 400;
  text-transform: uppercase;
  color: #151515;
  margin-top: 0px;
  margin-bottom: 25px;
}

/* .width-filter {
  max-width: 252px;
} */

.width-inner {
  width: 100%;
}

/* .video_data {
  max-width: 680px;
} */

.video_container {
  width: 100%;
}

.player_container {
  width: 100%;
  /* height: calc(40vw - 1rem); */
}
.player {
  width: 100%;
  height: 100%;

  /* height: 800px; */
}

table tbody tr:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity)) !important;
}

table tbody tr:nth-child(odd) {
  background-color: #f8f8f8;
}

table td,
th {
  padding: 0.5rem;
  line-height: 1.5rem;
}
.table {
  display: block;
  max-width: 100%;
}

.tr {
  display: flex;
}

.th,
.td {
  padding: 8px;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
}

.resizer {
  position: absolute;
  top: 0;
  height: 100%;
  right: 0;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: #add8e6;
  opacity: 0.5;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}

.th {
  position: relative;
}

@media only screen and (max-width: 2400px) {
}
@media only screen and (max-width: 1600px) {
}

@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 767px) {
  .notfound h2 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 141px;
  }
}
